@import url("https://fonts.googleapis.com/css2?family=Syne:wght@400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;500&display=swap");
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

body {
  /* background-color: #f8f7f7; */
  background-color: #F7F6F6;
    font-family: "IBM Plex Sans", sans-serif;
  /* color:#f6f7f8 */
}

a {
  text-decoration: none;
}
.carousel {
  position: relative;
  width: 100%;
  height: 300px;
}

.carousel-images {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.carousel-image {
  display: none;
  /* background-size: contain; */
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  /* background-repeat: repeat-x; */
}

.carousel-image.active {
  display: block;
  animation: fade 1s ease-in-out;
}

.carousel-dots {
  position: absolute;
  top: 100px;
  left: 95%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.carousel-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #2e1834;
  margin: 0 5px;
  cursor: pointer;
  transition: 0.5s all linear;
}

.carousel-dot.active {
  height: 30px;
  border-radius: 100px;
  background-color: #D7B9DF;
}
.carousel-caption {
  position: absolute;
  bottom: 30px;
  left: 20%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  background-color: #2e1834;
  padding: 25px;
  color: #fff;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.auto-moving-text-container {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
}

.auto-moving-text {
  animation: moveText 20s linear infinite;
  white-space: nowrap;
}

@keyframes moveText {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
.download_one {
  background: url("../assets//1.svg");
  background-repeat: no-repeat;
  height: 100px;
  padding-top: 15px;
  /* background-size: contain; */
}
.download_two {
  background: url("../assets//2.svg");
  background-repeat: no-repeat;
  height: 100px;
  padding-top: 15px;
}
.download_three {
  background: url("../assets//3.svg");
  background-repeat: no-repeat;
  height: 100px;
  padding-top: 15px;
}

.product_price {
  color: #1a1a1a !important;
  font-size: 24px !important;
  font-weight: 500 !important;
  line-height: 125% !important; /* 30px */
  letter-spacing: 0.24px !important;
}
.product_title {
  color: #1a1a1a !important;
  font-size: 32px !important;
  font-weight: 500 !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 125% !important; /* 40px */
  letter-spacing: 0.32px !important;
}

.bubble {
  position: relative;
  font-family: inter;
  background-color: #333;
  font-size: 18px;
  line-height: 24px;
  width: fit-content;
  border-radius: 0px;
  padding: 5px;
  /* text-align: center; */
  color: #fff;
}

.bubble-bottom-left:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 16px solid #333;
  border-right: 8px solid transparent;
  border-top: 8px solid #333;
  border-bottom: 20px solid transparent;
  left: 16px;
  bottom: -24px;
}
.confirmation_page {
  background: rgb(20, 139, 119);
  background: radial-gradient(
    circle,
    rgba(20, 139, 119, 1) 0%,
    rgba(4, 56, 47, 1) 100%
  );
  height: 100vh;
  display: grid;
  place-items: center;
}
.confirm_card {
  background: rgb(20, 139, 119);
  background: linear-gradient(
    0deg,
    rgba(20, 139, 119, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  height: 70vh;
  width: 30%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}
.no_search_list li {
  font-weight: 400;
  font-family: inter;
  font-size: 14px;
  padding: 5px 0px;
}

@media (max-width: 600px) {
  .carousel {
    height: 150px;
  }
  .carousel-dots {
    display: none;
  }
}

.hide_scrollbar::-webkit-scrollbar {
  display: none;

}

.hide_scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}



