@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800;900&display=swap');
body {
  margin: 0;
  font-family: 'IBM Plex Sans';
}
/* input,
textarea {
  touch-action: manipulation;
} */


input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.three-line-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.MuiModal-root:focus {
  outline: none;
}

.MuiModal-root .MuiBox-root:focus {
  outline: none;
}

.slick-slide {
  width: auto !important;

  /* or adjust as needed */
}

@keyframes originSlide {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}
.origins {
  overflow: hidden;
  padding: 10px 0px;
  /* background-color: white; */
  margin-top: 30px;
    white-space: nowrap;
    position: relative;
}
.origins::before{

  left: 0;
background: linear-gradient(to left, rgba(255, 255, 255, 0), #f6f7f8);

}
.origins::after{

  right: 0;
background: linear-gradient(to right, rgba(255, 255, 255, 0), #f6f7f8);

}
.origins::before,
.origins::after{
  position: absolute;
  top: 0;

  width: 150px;
  height: 100%;

content: "";
z-index: 2;
}
.origins-slide{
display:flex;
display: inline-flex;
animation: 30s originSlide infinite linear;
}
.origins:hover .origins-slide{
  animation-play-state: paused;
}
.origins-box{
display: flex;
flex-direction: column;
align-items: center;
    margin: 0px 30px;
    width: 50px;
    cursor: pointer;
}
.origins-slide img{
height: 40px;
/* margin: 0px 30px; */

}




